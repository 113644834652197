import React from "react";
import { Field } from "formik";
import { Grid } from "@material-ui/core";
import { TextField } from "formik-material-ui";

import styles from "./styles";
export const InputField = ({ label, helperText, type, name }) => {
  const classes = styles();
  return (
    <Grid item xs={12}>
      <Field
        component={TextField}
        name={name}
        label={label}
        helperText={helperText}
        inputProps={{
          className: classes.input,
        }}
        InputLabelProps={{
          className: classes.label,
        }}
        fullWidth
        type={type}
      />
    </Grid>
  );
};
export default InputField;
